import React from "react"
import Tip from "./Tip"

import { getPersonName, getRefId, getPersonLocation } from "../utils/utils.js"

const RoundAvatar = ({
    person,
    orgData,
    width = 30,
    style = {},
    noTip,
    noFlip = "true",
    tipPosition = "top",
    tipOffset,
    comp,
}) => {
    const styles = {
        borderRadius: "50%",
        display: "block",
        height: width,
        width: width,
        maxWidth: width,
        maxHeight: width,
        minHeight: width,
        minWidth: width,
        backgroundImage: `url(${person?.ref?.avatar || person?.avatar || "/img/avatar-blur.jpg"})`,
        backgroundColor: person?.invitePending ? "#fd2a7d" : "transparent",
        backgroundSize: "cover",
        backgroundPosition: "center center",
        backgroundBlendMode: person?.invitePending ? "luminosity" : "normal",
        ...style,
    }
    if (noTip) {
        return <div style={styles} />
    }

    return (
        <Tip
            noFlip={noFlip}
            offset={tipOffset}
            position={tipPosition}
            html={<Html person={person} orgData={orgData} comp={comp} />}
            style={styles}
        ></Tip>
    )
}

const Html = ({ person, orgData, mission, comp }) => {
    let personDetails = {}
    if (!person) {
        return <>Person not found</>
    }

    let orgPerson = orgData?.people?.find((p) => getRefId(p) === getRefId(person))
    if (orgPerson) {
        const re = orgData.roles?.find((r) => r._id === orgPerson?.role)
        const de = person?.departments ? orgData.departments?.find((r) => r._id === person.departments[0]) : null

        personDetails = {
            person: orgPerson,
            email: orgPerson.email,
            role: re?.name,
            department: de?.title || "No department",
        }
    }

    return (
        <div
            className="dna-dark x-ra-new"
            style={{ minWidth: 250, maxWidth: 400, display: "flex", flexDirection: "column", alignItems: "flex-start" }}
        >
            <h3>{getPersonName(person)}</h3>
            <div>{person.email}</div>
            {personDetails.role && <div>Org role: {personDetails.role || "Not assigned..."}</div>}

            {orgData && (
                <>
                    <div>Department: {personDetails.department || "Not assigned..."}</div>
                    <div>
                        Location:{" "}
                        {orgPerson?.office
                            ? orgData?.offices.find((o) => o._id === orgPerson.office)?.title
                            : getPersonLocation(person)}
                    </div>
                </>
            )}
            {comp}
        </div>
    )
}
export default RoundAvatar
