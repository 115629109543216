import React from "react"

import { v4 as uniqid } from "uuid"
import history from "./comp/History"
import { logError } from "./services/logging.service"

export default class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            hasError: false,
        }
    }

    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.

        return {
            hasError: true,
            error,
        }
    }

    componentDidCatch(error) {
        this.props.app?.unBlock()

        if (this.props.context) {
            logError(this.props.context + " - " + error)
        } else {
            logError(error)
        }

        const errorId = uniqid()

        this.props.app?.showAppElements()

        history.push("/home?diagnostic=" + errorId)
    }

    render() {
        if (this.state.hasError) return <></>
        return this.props.children
    }
}
