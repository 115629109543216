export const FILE_UPLOAD_SIZE_LIMIT_MB = 32
export const FILE_UPLOAD_LIMIT = 20

export const FILE_TYPE = {
    IMAGE: "image",
    IMAGE_RAW: "imageRaw",
    VIDEO: "video",
    ARCHIVE: "archive",
    AUDIO: "audio",
    FONT: "font",
    DOCUMENT: "document",
    CODE: "code",
}

export const EXTENSIONS_IMAGE_COMPRESSED = ["jpg", "jpeg", "png", "webp", "avif", "tif", "tiff", "heif", "heic", "avci"]

export const EXTENSIONS_IMAGE_RAW = [
    "nef",
    "cr2",
    "raw",
    "rw2",
    "wgi",
    "czi",
    "eip",
    "fpx",
    "pcd",
    "fff",
    "dcr",
    "kdc",
    "kqp",
    "arw",
    "lfr",
    "pef",
    "dng",
    "iiq",
    "raf",
    "rwl",
    "ari",
    "mdc",
    "r3d",
    "crw",
    "mos",
    "orf",
    "nrw",
    "sr2",
    "3fr",
    "x3f",
    "dc2",
    "mrw",
    "cib",
    "mef",
    "apu",
    "lfp",
    "grey",
    "srw",
    "exf",
    "erf",
    "ra2",
    "ndd",
    "nop",
    "srf",
    "st7",
    "olr",
    "cap",
    "ce2",
    "st5",
    "gry",
    "ptx",
    "st8",
    "sd1",
    "st4",
    "dcs",
    "gray",
    "craw",
    "kc2",
    "bay",
    "ce1",
    "ycbcra",
    "rwz",
    "3pr",
    "mfw",
    "st6",
    "nwb",
    "ia",
    "cmt",
    "stx",
    "m02",
    "sd0",
    "m03",
    "xcf",
    "tga",
    "thm",
]

export const EXTENSIONS_IMAGE = ["jpg", "jpeg", "png", "gif", "webp", "tif", "tiff", "svg"]

export const EXTENSIONS_VIDEO = [
    "3g2",
    "3gp",
    "aaf",
    "asf",
    "avchd",
    "avi",
    "drc",
    "flv",
    "m2v",
    "m4p",
    "m4v",
    "mkv",
    "mng",
    "mov",
    "mp2",
    "mp4",
    "mpe",
    "mpeg",
    "mpg",
    "mpv",
    "mxf",
    "nsv",
    "ogg",
    "ogv",
    "ogm",
    "qt",
    "rm",
    "rmvb",
    "roq",
    "webm",
    "wmv",
    "yuv",
]

export const EXTENSIONS_ARCHIVE = [
    "7z",
    "a",
    "apk",
    "pkg",
    "ar",
    "bz2",
    "cab",
    "cpio",
    "deb",
    "dmg",
    "egg",
    "gz",
    "iso",
    "jar",
    "lha",
    "mar",
    "pea",
    "rar",
    "rpm",
    "s7z",
    "shar",
    "tar",
    "tbz2",
    "tgz",
    "tlz",
    "war",
    "whl",
    "xpi",
    "zip",
    "zipx",
    "deb",
    "rpm",
    "xz",
    "pak",
    "crx",
    "exe",
    "msi",
    "bin",
]

export const EXTENSIONS_AUDIO = [
    "aac",
    "aiff",
    "ape",
    "au",
    "flac",
    "gsm",
    "it",
    "m3u",
    "m4a",
    "mid",
    "mod",
    "mp3",
    "mpa",
    "pls",
    "ra",
    "s3m",
    "sid",
    "wav",
    "wma",
    "xm",
]

export const EXTENSIONS_FONT = ["eot", "otf", "ttf", "woff", "woff2"]

export const EXTENSIONS_CODE = [
    "c",
    "cc",
    "class",
    "clj",
    "cpp",
    "cs",
    "cxx",
    "el",
    "go",
    "h",
    "java",
    "lua",
    "m",
    "m4",
    "php",
    "pl",
    "po",
    "py",
    "rb",
    "rs",
    "sh",
    "swift",
    "vb",
    "vcxproj",
    "xcodeproj",
    "xml",
    "diff",
    "patch",
    "html",
    "htm",
    "css",
    "js",
    "jsx",
    "less",
    "scss",
    "wasm",
    "json",
]

export const EXTENSIONS_DOCUMENT = [
    "ods",
    "xls",
    "xlsx",
    "csv",
    "ics",
    "vcf",
    "ppt",
    "odp",
    "doc",
    "docx",
    "ebook",
    "log",
    "md",
    "msg",
    "odt",
    "org",
    "pages",
    "pdf",
    "rtf",
    "rst",
    "tex",
    "txt",
    "wpd",
    "wps",
]

export const EXTENSIONS_SHEETS = [
    "application/vnd.ms-excel", // .xls
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", // .xlsx
    "text/csv", // .csv
]

export const FILE_TYPE_BY_EXTENSION_MAP = new Map([
    ...EXTENSIONS_IMAGE.map((item) => [item, FILE_TYPE.IMAGE]),
    ...EXTENSIONS_IMAGE_RAW.map((item) => [item, FILE_TYPE.IMAGE_RAW]),
    ...EXTENSIONS_VIDEO.map((item) => [item, FILE_TYPE.VIDEO]),
    ...EXTENSIONS_ARCHIVE.map((item) => [item, FILE_TYPE.ARCHIVE]),
    ...EXTENSIONS_AUDIO.map((item) => [item, FILE_TYPE.AUDIO]),
    ...EXTENSIONS_FONT.map((item) => [item, FILE_TYPE.FONT]),
    ...EXTENSIONS_DOCUMENT.map((item) => [item, FILE_TYPE.DOCUMENT]),
    ...EXTENSIONS_CODE.map((item) => [item, FILE_TYPE.CODE]),
])
