import { ensureNumber, getAllActionItems, getAllActions } from "../comp/MissionUtils"
import { getObjectId, getRefId } from "../comp/Utils"
import { toZonedTime } from "date-fns-tz"
import areIntervalsOverlapping from "date-fns/areIntervalsOverlapping"
import { isAfter } from "date-fns/isAfter"
import { rolesRequireApproval } from "../utils/allocation"
import { isSameMonth } from "date-fns/isSameMonth"
import { eachMonthOfInterval } from "date-fns/eachMonthOfInterval"
import eachWeekOfInterval from "date-fns/eachWeekOfInterval"
import { eachDayOfInterval } from "date-fns/eachDayOfInterval"
import { endOfDay } from "date-fns/endOfDay"
import addDays from "date-fns/addDays"
import { getInvoicedAmounts } from "../utils/invoices"
import { isBefore } from "date-fns/isBefore"
import { startOfMonth } from "date-fns/startOfMonth"
import startOfDay from "date-fns/startOfDay"
import endOfMonth from "date-fns/endOfMonth"
import isLastDayOfMonth from "date-fns/isLastDayOfMonth"
import isFirstDayOfMonth from "date-fns/isFirstDayOfMonth"
import differenceInBusinessDays from "date-fns/differenceInBusinessDays"
import { getTimesheetHoursFromTo, getTimeEntryHours } from "../comp/DateUtils"

import { existsTimeTracking } from "../comp/MissionUtils"
import { isWithinInterval } from "date-fns/isWithinInterval"
import isFriday from "date-fns/isFriday"
import { isSameDay } from "date-fns/isSameDay"
import isMonday from "date-fns/isMonday"
import isWeekend from "date-fns/isWeekend"
import isSameWeek from "date-fns/isSameWeek"
import getISODay from "date-fns/getISODay"
import { startOfISOWeek } from "date-fns/startOfISOWeek"
import { endOfISOWeek } from "date-fns/endOfISOWeek"
import { getGreatestEndDate, getSmallestStartDate, getTimeUtc } from "../utils/dates"
import { getExchangeRates, getFxForTheDay } from "../utils/exchange-rates"
import uniq from "lodash/uniq"
import flatMap from "lodash/flatMap"

export function isMissionBillable(mission) {
    if (mission.notBillable) {
        return false
    }

    if (mission.isModel) {
        return true
    }
    if (mission.org && mission.isTemplate) {
        return true
    }

    return mission.client
}

export async function fxFinancials({
    missions,
    mission,
    timesheets = [],
    invoices = [],
    orgData,
    from,
    to,
    app,
    exchangeRates,
}) {
    if (!missions && mission) {
        missions = [mission]
    }
    const currenciesUsed = uniq([...flatMap(missions, "currency"), ...flatMap(invoices || [], "currency")]).filter(
        (c) => !!c && c !== orgData?.currency
    )

    if (currenciesUsed.length && !exchangeRates) {
        try {
            exchangeRates = await getExchangeRates({
                baseCurrency: orgData.currency,
                currencies: currenciesUsed,
            })
        } catch (e) {
            throw e
        }
    }

    return missionFinancials({
        missions,
        from,
        to,
        exchangeRates,
        app: app,
        orgData,
    })
}

export function missionFinancials({
    missions,
    mission,
    timesheets = [],
    invoices = [],
    orgData,
    from,
    to,
    exchangeRates,
    app,
    dead,
}) {
    let data = {
        estCost: 0,
        actualCost: 0,
        actualHours: 0,
        estRevenue: 0,
        invoiced: 0,
        collected: 0,
        actualRevenue: 0,
        peopleCost: 0,
        peopleRev: 0,
        lineItemCost: 0,
        lineItemRev: 0,
        lineItemActualCost: 0,
        lineItemActualRev: 0,
        budgetAvailable: 0,
    }

    if (dead) {
        return data
    }

    if (from && to && isAfter(from, to)) {
        return data
    }

    if (mission && !missions) {
        missions = [mission]
    }

    missions.forEach((mission, i) => {
        const approvalNeeded = orgData ? rolesRequireApproval({ mission, orgData }) : null

        const isBillable = isMissionBillable(mission) || mission.isModel

        let useMultiplier = false

        if (exchangeRates) {
            useMultiplier = exchangeRates && mission.currency !== orgData.currency
        }

        const fxm = (d) => {
            return getFxForTheDay({
                useMultiplier,
                currency: mission.currency,
                exchangeRates,
                day: d,
                app,
            })
        }

        data.budgetAvailable += (mission.budgetAvailable || 0) * fxm(toZonedTime(mission.planEndDate))

        const roles = mission.planItems.filter((p) => {
            if (!p?.type) {
                return false
            }
            if (p.type !== "person") {
                return false
            }

            if (approvalNeeded && !p.createdFromGtCrm) {
                return false
            }
            return true
        })

        if (mission.projectType === "mx-gantt") {
            getAllActionItems(mission).forEach((ai, i) => {
                const aiData = getActionItemCostRevenue({ ai, mission })
                data.estCost += aiData.estCost
                data.estRevenue += aiData.estRevenue
                data.actualRevenue += aiData.actualRevenue
            })
        }

        const missionTimesheets = timesheets.filter(
            (ts) => ts.status === "approved" && getObjectId(ts.mission) === mission._id
        )

        roles.forEach((planItem, i) => {
            const roleTimesheets = missionTimesheets.filter(
                (ts) => ts.planItemId === planItem._id && ts.status === "approved"
            )

            const costRevenueForRole = getRoleItemCostAndRevenue({ planItem, mission, from, to, roleTimesheets, fxm })

            if (mission.projectType !== "mx-gantt") {
                data.estCost += ensureNumber(costRevenueForRole.estCost)
                data.peopleCost += ensureNumber(costRevenueForRole.estCost)
            }

            data.actualCost += ensureNumber(costRevenueForRole.actualCost)

            if (isBillable && !mission.billUsingMilestones) {
                if (mission.projectType !== "mx-gantt") {
                    data.estRevenue += ensureNumber(costRevenueForRole.estRevenue)
                    data.peopleRev += ensureNumber(costRevenueForRole.estRevenue)
                }

                data.actualRevenue += ensureNumber(costRevenueForRole.actualRevenue)
            }
        })
        const lineItems = mission.planItems.filter((p) => p.type === "cost")
        lineItems.forEach((planItem, i) => {
            const costRevenueForLineItem = getLineItemCostAndRevenue({ planItem, mission, from, to, fxm })

            data.lineItemCost += ensureNumber(costRevenueForLineItem.estCost)
            data.estCost += ensureNumber(costRevenueForLineItem.estCost)
            data.actualCost += ensureNumber(costRevenueForLineItem.actualCost)
            data.lineItemActualCost += ensureNumber(costRevenueForLineItem.actualCost)

            if (isBillable) {
                data.actualRevenue += ensureNumber(costRevenueForLineItem.actualRevenue)
                data.estRevenue += ensureNumber(costRevenueForLineItem.estRevenue)
                data.lineItemRev += ensureNumber(costRevenueForLineItem.estRevenue)
                data.lineItemActualRev += ensureNumber(costRevenueForLineItem.actualRevenue)
            }
        })

        if (mission.billUsingMilestones) {
            let msRevenue = 0
            let msActualRev = 0
            mission.planItems.forEach((ms, i) => {
                if (ms.type !== "fundable_milestone") {
                    return
                }

                let isInRange = false

                try {
                    isInRange =
                        !from && !to
                            ? true
                            : isWithinInterval(toZonedTime(ms.endDate), {
                                  start: from,
                                  end: to,
                              })
                } catch (e) {}

                if (isInRange) {
                    const fm = fxm(toZonedTime(ms.endDate))
                    msRevenue += ensureNumber(ms.billRate || ms.amount) * fm
                    if (ms.approved) {
                        msActualRev += ensureNumber(ms.billRate || ms.amount) * fm
                    }
                }
            })
            data.actualRevenue += msActualRev
            data.estRevenue += msRevenue
        }

        getAllActionItems(mission).forEach((ai, i) => {
            ai.approvals?.forEach((cr, i) => {
                if (cr.approvedOn) {
                    if (from && to && !isWithinInterval(toZonedTime(cr.approvedOn), { start: from, end: to })) {
                        return
                    } else if (from && !to && isBefore(toZonedTime(cr.approvedOn), from)) {
                        return
                    }

                    if (isBillable) {
                        data.estRevenue += ensureNumber(cr.billRate)
                    } else {
                        data.estCost += ensureNumber(cr.billRate)
                    }
                }
            })
        })

        if (invoices.length) {
            let invoiceData
            try {
                invoiceData = getInvoicedAmounts({
                    invoices: invoices.filter((inv) => inv?.missionIds?.includes(mission._id)),
                    from,
                    to,
                })
            } catch (e) {
                throw e
            }

            data.invoiced += invoiceData?.invoiced || 0
            data.collected += invoiceData?.collected || 0
        }
    })

    return data
}

export function getActionPercentComplete(action) {
    const { actionItems } = action

    return (
        actionItems?.reduce((cumm, ai) => (cumm += ensureNumber(ai.percentComplete)), 0) ||
        ensureNumber(action.percentComplete)
    )
}

export function getRoleItemCostAndRevenue({
    planItem,
    from,
    to,
    mission,
    roleTimesheets = [],
    fxm,
    forceCalculate,
    exchangeRates,
    app,
}) {
    if (exchangeRates && !fxm) {
        fxm = (d) => {
            return getFxForTheDay({
                useMultiplier: true,
                currency: mission?.currency,
                exchangeRates,
                day: d,
                app,
            })
        }
    }

    let data = {
        actualHours: 0,
        actualCost: 0,
        estCost: 0,
        estRevenue: 0,
        actualRevenue: 0,
        conflictingTimesheetActionItems: [],
        conflictingHours: 0,
    }

    if (!mission || !planItem) {
        return data
    }

    if (planItem?.createdFromGtCrm) {
        return data
    }

    if (!fxm) {
        fxm = () => 1
    }

    let hourlyRates = getHourlyRates(planItem)

    if (from && to && isAfter(from, to)) {
        return data
    }

    if (roleTimesheets?.length === 0) {
        roleTimesheets = mapPlanItemTimesheets(planItem)
    }

    if (roleTimesheets.length) {
        if (!from) from = toZonedTime(planItem.startDate)
        if (!to) to = toZonedTime(planItem.endDate)

        /*if (conflictingTimesheetActionItems.length === 0) {
            const totalRoleHours = ensureNumber(getTimesheetHoursFromTo(roleTimesheets, from, to))

            data.actualHours = totalRoleHours * fxm(to)
            data.actualCost = totalRoleHours * hourlyRates.rate * fxm(to)
            data.actualRevenue =
                mission.billUsingMilestones || mission.notBillable ? 0 : totalRoleHours * hourlyRates.billRate * fxm(to)
        } else {*/
        roleTimesheets.forEach((ts, i) => {
            const isTsIn = areIntervalsOverlapping(
                {
                    start: from,
                    end: to,
                },
                { start: toZonedTime(ts.weekStart), end: toZonedTime(ts.weekEnd) }
            )

            if (isTsIn) {
                ts.actionItems.forEach((tsa, i) => {
                    //let isAConflict = conflictingTimesheetActionItems.find((cf) => cf._id === tsa.actionItemId)
                    //if (!isAConflict) {
                    let startIndex = 0
                    let endIndex = 6

                    if (isSameWeek(toZonedTime(ts.weekStart), from, { weekStartsOn: 1 })) {
                        startIndex = getISODay(from) - 1
                    }
                    if (isSameWeek(toZonedTime(ts.weekEnd), to, { weekStartsOn: 1 })) {
                        endIndex = getISODay(to) - 1
                    }

                    let myHoursHere = ensureNumber(getTimeEntryHours(tsa, startIndex, endIndex))

                    let tsRates = getHourlyRates(ts.snapshot?.planItem || ts.planItem || ts)

                    data.actualHours += myHoursHere
                    data.actualCost += myHoursHere * tsRates.rate * fxm(toZonedTime(ts.weekEnd))
                    data.actualRevenue +=
                        mission.billUsingMilestones || mission.notBillable
                            ? 0
                            : myHoursHere * tsRates.billRate * fxm(toZonedTime(ts.weekEnd))
                    //}
                })
            }
        })
        //}
    }

    if (mission.isProcess || mission.projectType === "mx-gantt") {
        const b = getRaciBudgetForRole(planItem)
        data.estCost = b.estCost
        data.estRevenue = b.estRevenue

        return data
    }

    if (planItem.hoursNeeded >= 0 && planItem.hoursNeeded !== null) {
        if (isOneTimePlanItemInRangeCheck({ planItem, from, to })) {
            data.estCost =
                planItem.rateTime === "One time"
                    ? planItem.rate
                    : planItem.hoursNeeded * hourlyRates.rate * fxm(toZonedTime(planItem.endDate))
            data.estRevenue =
                (mission.billUsingMilestones || mission.notBillable) && !forceCalculate
                    ? 0
                    : planItem.billUnit === "One time"
                    ? planItem.billRate
                    : planItem.hoursNeeded * hourlyRates.billRate * fxm(toZonedTime(planItem.endDate))

            return data
        } else {
            return data
        }
    } else {
        let isInRange = isRecurringPlanItemInRangeCheck({ planItem, from, to, mission })

        if (isInRange) {
            //Main logic
            let cost = 0
            let bill = 0

            //Do cost
            cost = getAmountBasedOnRate({ planItem, rateToUse: "rate", timeToUse: "rateTime", from, to, fxm })

            bill = getAmountBasedOnRate({ planItem, rateToUse: "billRate", timeToUse: "billUnit", from, to, fxm })

            //const subMe = data.conflictingHours ? getHourlyRates(planItem).billRate * data.conflictingHours : 0

            data.estCost = ensureNumber(cost)
            data.estRevenue = ensureNumber(bill) //mission.billUsingMilestones ? 0 : bill - subMe) //For stupid tasks that are fixed bid
            return data
        } else {
            return data
        }
    }
}

function getStartDateToUse(planItem, from) {
    const piStartDate = toZonedTime(planItem.startDate)

    if (!from) {
        return piStartDate
    }
    if (isAfter(from, piStartDate)) {
        return from
    }

    return piStartDate
}
function getEndDateToUse(planItem, to) {
    const piEndDate = toZonedTime(planItem.endDate)

    if (!to) {
        return piEndDate
    }
    if (isAfter(piEndDate, to)) {
        return to
    }

    return piEndDate
}

const getAmountBasedOnRate = ({ planItem, rateToUse, timeToUse, from, to, fxm }) => {
    let amount = 0

    if (from && to && isAfter(from, to)) {
        return 0
    }

    if (!fxm) {
        fxm = () => 1
    }

    let startDateToUse = getStartDateToUse(planItem, from) //getStartDateToUsefrom && isBefore from || toZonedTime(planItem.startDate)
    let endDateToUse = getEndDateToUse(planItem, to)
    let adjustedEndDate = startOfDay(addDays(endDateToUse, 1))

    const hourlyRates = getHourlyRates(planItem)

    const dayRate = ensureNumber(hourlyRates[rateToUse]) * 8

    const myRate = ensureNumber(planItem[rateToUse])

    if (planItem[timeToUse] === "One time") {
        amount = (planItem[rateToUse] || 0) * (planItem.quantity || 1)
    } else {
        if (planItem[timeToUse] === "Yearly") {
            const bizDays = differenceInBusinessDays(adjustedEndDate, startDateToUse)
            amount = bizDays * 8 * hourlyRates[rateToUse]
        } else if (planItem[timeToUse] === "Monthly") {
            let fullMonths = 0

            try {
                fullMonths = eachMonthOfInterval({
                    start: startDateToUse,
                    end: endDateToUse,
                }).length
            } catch (e) {}

            if (!isFirstDayOfMonth(startDateToUse)) {
                fullMonths--
            }
            if (!isLastDayOfMonth(endDateToUse)) {
                fullMonths--
            }

            if (fullMonths === 0 || fullMonths < 0) {
                const bizDays = differenceInBusinessDays(adjustedEndDate, startDateToUse)

                amount = bizDays * dayRate
            } else {
                amount = fullMonths * myRate

                if (!isFirstDayOfMonth(startDateToUse)) {
                    //get partial month days at the start
                    const daysTillFullMonth = differenceInBusinessDays(endOfMonth(startDateToUse), startDateToUse)

                    amount += daysTillFullMonth * dayRate
                }
                if (!isLastDayOfMonth(endDateToUse)) {
                    //get partial month days at the end
                    const daysAfterFullMonth = differenceInBusinessDays(adjustedEndDate, startOfMonth(endDateToUse))

                    amount += daysAfterFullMonth * dayRate
                }
            }
        } else if (planItem[timeToUse] === "Weekly") {
            let fullWeeks = eachWeekOfInterval(
                {
                    start: startDateToUse,
                    end: endDateToUse,
                },
                {
                    weekStartsOn: 1,
                }
            ).length

            if (!isMonday(startDateToUse)) {
                fullWeeks--
            }
            if (!isFriday(endDateToUse) && !isWeekend(endDateToUse)) {
                fullWeeks--
            }

            if (fullWeeks === 0 || fullWeeks < 0) {
                const bizDays = differenceInBusinessDays(adjustedEndDate, startDateToUse)
                amount = bizDays * dayRate
            } else {
                amount = fullWeeks * myRate

                if (!isMonday(startDateToUse)) {
                    //get partial month days at the start
                    const daysTillFullWeek = differenceInBusinessDays(endOfISOWeek(startDateToUse), startDateToUse)

                    amount += daysTillFullWeek * dayRate
                }
                if (!isFriday(endDateToUse) && !isWeekend(endDateToUse)) {
                    //get partial month days at the end
                    const daysAfterFullWeek = differenceInBusinessDays(adjustedEndDate, startOfISOWeek(endDateToUse))

                    amount += daysAfterFullWeek * dayRate
                }
            }
        } else if (planItem[timeToUse] === "Daily") {
            const bizDays = differenceInBusinessDays(adjustedEndDate, startDateToUse)
            amount = bizDays * myRate
        } else if (planItem[timeToUse] === "Hourly") {
            const bizzDays = differenceInBusinessDays(adjustedEndDate, startDateToUse)

            amount = bizzDays * myRate * 8
        }
    }

    return amount * fxm(endDateToUse)
}

function isOneTimePlanItemInRangeCheck({ planItem, from, to }) {
    if (!from && !to) {
        return true
    }

    if (!planItem.startDate || !planItem.endDate) {
        return false //Bad data
    }

    if (!from) from = toZonedTime(planItem.startDate)
    if (!to) to = toZonedTime(planItem.endDate)

    let isIn = false

    try {
        const st = startOfDay(toZonedTime(planItem.endDate))
        const ed = endOfDay(toZonedTime(planItem.endDate)) //catch the last date

        isIn =
            areIntervalsOverlapping(
                {
                    start: startOfDay(from),
                    end: endOfDay(to),
                },
                {
                    start: st,
                    end: ed,
                }
            ) ||
            isSameDay(from, st) ||
            isSameDay(to, ed) ||
            isWithinInterval(ed, {
                start: startOfDay(from),
                end: endOfDay(to),
            })
    } catch (e) {
        throw e
    }

    return isIn
}
function isRecurringPlanItemInRangeCheck({ planItem, from, to, mission }) {
    if (!from && !to) {
        return true
    }

    if (!planItem.startDate || !planItem.endDate) {
        return false //Bad data
    }

    if (!from) from = toZonedTime(planItem.startDate)
    if (!to) to = toZonedTime(planItem.endDate)

    let isIn = false

    const utStart = toZonedTime(planItem.startDate)
    const utEnd = endOfDay(toZonedTime(planItem.endDate))

    try {
        isIn = areIntervalsOverlapping(
            {
                start: from,
                end: to,
            },
            {
                start: utStart,
                end: utEnd,
            }
        )
    } catch (e) {}

    return isIn
}

export function mapPlanItemTimesheets(pi) {
    if (!pi.timesheets?.length) {
        return []
    } else {
        return (pi.timesheets || []).map((t) => {
            return {
                ...t,
                weekStart: t.weekStart,
                weekEnd: getTimeUtc(endOfISOWeek(toZonedTime(t.weekStart))),
                actionItems: [
                    {
                        days: t.dailyHours?.join(",") || "0,0,0,0,0,0,0,0",
                    },
                ],
            }
        })
    }
}

export function getLineItemCostAndRevenue({ planItem, from, to, mission, fxm }) {
    let data = {
        estCost: 0,
        estRevenue: 0,
        actualCost: 0,
        actualRevenue: 0,
    }

    if (!fxm) {
        fxm = () => 1
    }

    if (from && to && isAfter(from, to)) {
        return data
    }

    if (!from && !to && planItem.rateTime === "One time") {
        data.estCost = ensureNumber(planItem.rate) * (planItem.quantity || 1) * fxm(toZonedTime(planItem.endDate))
        data.estRevenue =
            ensureNumber(planItem.billRate) * (planItem.quantity || 1) * fxm(toZonedTime(planItem.endDate))

        if (isBefore(toZonedTime(planItem.endDate), endOfMonth(new Date()))) {
            data.actualCost =
                ensureNumber(planItem.rate) * (planItem.quantity || 1) * fxm(toZonedTime(planItem.endDate))
            data.actualRevenue =
                ensureNumber(planItem.billRate) * (planItem.quantity || 1) * fxm(toZonedTime(planItem.endDate))
        }
    } else if (planItem.rateTime === "One time") {
        const isInRange = isOneTimePlanItemInRangeCheck({ planItem, from, to })

        if (isInRange) {
            data.estCost = ensureNumber(planItem.rate) * (planItem.quantity || 1) * fxm(toZonedTime(planItem.endDate))
            data.estRevenue =
                ensureNumber(planItem.billRate) * (planItem.quantity || 1) * fxm(toZonedTime(planItem.endDate))
        }

        if (isBefore(toZonedTime(planItem.endDate), endOfMonth(new Date()))) {
            data.actualCost =
                ensureNumber(planItem.rate) * (planItem.quantity || 1) * fxm(toZonedTime(planItem.endDate))
            data.actualRevenue =
                ensureNumber(planItem.billRate) * (planItem.quantity || 1) * fxm(toZonedTime(planItem.endDate))
        }
    } else {
        return getIntervalOfData({ planItem, from, to, mission, fxm })
    }

    return data
}

export function getHourlyRates(planItem) {
    if (!planItem) {
        return { billRate: 0, rate: 0 }
    }

    const rate = ensureNumber(planItem.rate)
    const billRate = ensureNumber(planItem.billRate)

    const factor = {
        Hourly: { rate: 1, billRate: 1 },
        Weekly: { rate: 1 / 40, billRate: 1 / 40 },
        Monthly: { rate: (1 / 40) * (12 / 52), billRate: (1 / 40) * (12 / 52) },
        Daily: { rate: 1 / 8, billRate: 1 / 8 },
        Yearly: { rate: (1 / 40) * (1 / 52), billRate: (1 / 40) * (1 / 52) },
    }

    const { rate: rateFactor, billRate: billRateFactor } = factor[planItem.rateTime] || { rate: 0, billRate: 0 }

    const myRate = rate * rateFactor
    const myBillRate = billRate * billRateFactor

    return {
        billRate: ensureNumber(myBillRate),
        rate: ensureNumber(myRate),
    }
}

const getIntervalOfData = ({ planItem, mission, from, to, fxm }) => {
    let data = {
        estCost: 0,
        estRevenue: 0,
    }

    if (!fxm) {
        fxm = () => 1
    }

    const piStartDate = toZonedTime(planItem.startDate)
    const piEndDate = toZonedTime(planItem.endDate)

    const methodToUse =
        planItem.rateTime === "Monthly"
            ? eachMonthOfInterval
            : planItem.rateTime === "Weekly"
            ? eachWeekOfInterval
            : eachDayOfInterval

    const isInRange = isRecurringPlanItemInRangeCheck({ planItem, from, to, mission })

    if (isInRange) {
        const startDateToUse = !from
            ? piStartDate
            : isAfter(piStartDate, from) || isSameMonth(piStartDate, from)
            ? piStartDate
            : from
        const endDateToUse = !to ? piEndDate : isAfter(to, piEndDate) || isSameMonth(piEndDate, to) ? piEndDate : to

        let numberOfIterations = 0
        let estC = 0
        let estR = 0

        numberOfIterations = methodToUse(
            {
                start: startDateToUse,
                end: endDateToUse,
            },
            { weekStartsOn: 1 }
        ).forEach((time, i) => {
            estC += ensureNumber(planItem.rate) * ensureNumber(planItem.quantity) * fxm(time)
            estR += ensureNumber(planItem.billRate) * ensureNumber(planItem.quantity) * fxm(time)
        })

        return {
            estCost: ensureNumber(estC),
            estRevenue: ensureNumber(estR),
        }
    } else {
        return data
    }
}

export function getMilestoneRevenue({ planItem, from, to }) {
    if (from && to && isAfter(from, to)) {
        return 0
    }

    let isInRange = !from && !to

    if (!isInRange) {
        try {
            isInRange = isWithinInterval(toZonedTime(planItem.endDate), {
                start: from,
                end: to,
            })
        } catch (e) {}
    }

    if (isInRange) {
        return ensureNumber(planItem.billRate)
    }

    return 0
}

export function getActionItemCostRevenue({ ai, mission, totals }) {
    let estCost = 0,
        estRevenue = 0,
        hoursNeeded = 0

    const rolePlanItems = mission.planItems.filter(
        (p) => p.type === "person" && p.raciOnTasks?.find((r) => r.taskId === ai._id)
    )

    rolePlanItems.forEach((pi) => {
        const hrlyRate = getHourlyRates(pi)

        pi.raciOnTasks
            .filter((r) => r.taskId === ai._id)
            .forEach((r, i) => {
                estCost += ensureNumber(r.hoursNeeded) * ensureNumber(hrlyRate.rate)
                estRevenue += ensureNumber(r.hoursNeeded) * ensureNumber(hrlyRate.billRate)
                hoursNeeded += ensureNumber(r.hoursNeeded)
            })
    })

    if (totals)
        mission.planItems.forEach((p, i) => {
            if (p.linkedToTask === ai._id && p.type === "cost") {
                const budget = getLineItemCostAndRevenue({
                    planItem: p,
                    mission,
                })
                estCost += budget.estCost
                estRevenue += budget.estRevenue
            }
        })

    if (!isMissionBillable(mission)) {
        estRevenue = 0
    }

    return {
        estCost,
        estRevenue,
        hoursNeeded,
        actualRevenue: estRevenue ? estRevenue * (ai.percentComplete / 100) : 0,
    }
}

export function getMissionHours({ mission, timesheets, from, to }) {
    let hours = {
        planned: 0,
        actual: 0,
    }

    mission.planItems
        .filter((p) => {
            return p.type === "person"
        })
        .forEach((planItem, i) => {
            const myHours = getHoursForRole({ planItem, timesheets, from, to })

            hours.planned += myHours.planned
            hours.actual += myHours.actual
        })

    return hours
}

export function getHoursForRole({ planItem, timesheets = [], from, to }) {
    let hours = {
        planned: 0,
        actual: 0,
    }

    let roleTimesheets = []

    if (timesheets.length === 0) {
        roleTimesheets = (planItem.timesheets || []).map((t) => {
            return {
                weekStart: t.weekStart,
                weekEnd: getTimeUtc(endOfISOWeek(toZonedTime(t.weekStart))),
                actionItems: [
                    {
                        days: t.dailyHours?.join(",") || "0,0,0,0,0,0,0,0",
                    },
                ],
            }
        })
    } else {
        timesheets.filter((ts) => ts.planItemId === planItem._id && ts.status === "approved")
    }

    if (!from || !to) {
        from = getSmallestStartDate(roleTimesheets.map((ts) => ({ startDate: ts.weekStart })))
        to = getGreatestEndDate(
            roleTimesheets.map((ts) => ({
                endDate: getTimeUtc(endOfISOWeek(toZonedTime(ts.weekStart))),
            }))
        )
    }

    hours.actual = ensureNumber(getTimesheetHoursFromTo(roleTimesheets, from, to))

    if (planItem.hoursNeeded) {
        hours.planned = planItem.hoursNeeded || 0
    } else {
        const st = toZonedTime(planItem.startDate)
        const ed = toZonedTime(planItem.endDate)

        const d = differenceInBusinessDays(ed, st)

        hours.planned = d * 8
    }

    return hours
}

export function getCheckListCostAndRevenue(checklist) {
    let totalCost = 0
    let totalBill = 0

    checklist.forEach((st) => {
        const dur = st.actualDuration || st.duration
        totalCost += (st.rate || 0) * (dur === 0 ? 0 : dur || 1)
        totalBill += (st.billRate || 0) * (dur === 0 ? 0 : dur || 1)
    })

    return {
        estCost: totalCost,
        estRevenue: totalBill,
    }
}

export async function getExpenseTotal({ expenses, currency }) {
    let total = 0
    let rates

    if (expenses.find((ex) => ex.currency !== currency)) {
        rates = await getExchangeRates({ baseCurrency: currency })
    }

    expenses.forEach((exp, i) => {
        if (exp.status !== "approved" && exp.status !== "paid") return

        if (exp.currency !== currency) {
            const fx = rates ? rates[exp.currency] : 1
            const myTotal = ensureNumber(getExpenseAmount(exp))

            total += myTotal / fx
        } else {
            total += ensureNumber(getExpenseAmount(exp))
        }
    })

    return total
}

export function getExpenseAmount(exp, orgData) {
    let expenseAmount = ensureNumber(exp.amount)

    if (orgData?.expenseInvoiceOptions?.includes("tip")) {
        expenseAmount += ensureNumber(exp.tip)
    }
    if (orgData?.expenseInvoiceOptions?.includes("tax")) {
        expenseAmount += ensureNumber(exp.tax1) + ensureNumber(exp.tax2)
    }

    return expenseAmount
}
export function getAllChildrenThatAreActions(id, actionData) {
    let allChildren = []
    function getChildren(myId) {
        let immediateChildren = actionData.filter((d) => d.parentId === myId)
        allChildren = [...allChildren, ...immediateChildren]
        immediateChildren.forEach((item, i) => {
            getChildren(item._id)
        })
    }

    getChildren(id)

    return allChildren
}
export function getActionHours({ action, mission }) {
    const allActionKids = getAllChildrenThatAreActions(action._id, getAllActions(mission))

    const allTaskKids = [...(action.actionItems || []), ...flatMap(allActionKids, "actionItems")]

    let hours = 0

    if (allTaskKids.length === 0) {
        const d = eachDayOfInterval({
            start: toZonedTime(action.startDate),
            end: toZonedTime(action.endDate),
        }).filter((d) => !isWeekend(d)).length

        hours += d * 8
    } else {
        allTaskKids.forEach((ai, i) => {
            if (ai.actualDuration) {
                hours += ensureNumber(ai.actualDuration)
            } else {
                const d = eachDayOfInterval({
                    start: toZonedTime(ai.startDate),
                    end: toZonedTime(ai.endDate),
                }).filter((d) => !isWeekend(d)).length

                hours += ensureNumber(d * 8)
            }
        })
    }

    return ensureNumber(hours)
}

export function getBudgetForAction({ action, mission }) {
    let data = {
        estRevenue: 0,
        actualRevenue: 0,
        estCost: 0,
        actualCost: 0,
        hours: 0,
        percentComplete: 0,
    }

    const allActions = [action, ...getAllChildrenThatAreActions(action._id, getAllActions(mission))]

    const allTaskKidsForBudet = flatMap(
        allActions.filter((a) => !a.rate && !a.billRate),
        "actionItems"
    )

    allActions.forEach((ac, i) => {
        data.hours += getActionHours({ action: ac, mission })
    })

    allTaskKidsForBudet.forEach((ai, i) => {
        const b = getActionItemCostRevenue({ ai, mission })

        data.estRevenue += b.estRevenue
        data.actualRevenue += ensureNumber(Math.round(b.estRevenue * ensureNumber(ai.percentComplete / 100)))

        data.estCost += b.estCost
        data.actualCost += ensureNumber(Math.round(b.estCost * ensureNumber(ai.percentComplete / 100)))
    })

    return data
}

export const getRaciBudgetForRole = (planItem) => {
    let totals = {
        hoursNeeded: 0,
        estCost: 0,
        estRevenue: 0,
    }

    const rt = planItem.raciOnTasks

    if (rt?.length) {
        const hrlyRate = getHourlyRates(planItem)
        totals.hoursNeeded += rt.reduce((cumm, obj) => (cumm += ensureNumber(obj.hoursNeeded)), 0)
        totals.estCost += ensureNumber(totals.hoursNeeded) * ensureNumber(hrlyRate.rate)
        totals.estRevenue += ensureNumber(totals.hoursNeeded) * ensureNumber(hrlyRate.billRate)
    }

    return totals
}

export function getProcessTaskBudget({ ai, mission }) {
    return getActionItemCostRevenue({ ai, mission, totals: true })
}

export function getRoleRates({ role, orgData, mission }) {
    if (!role) {
        return role
    }

    if (mission.client) {
        const rc = orgData.rateCards?.find((rc) => rc.usedForId === mission.client)

        if (rc) {
            const roleRate = rc.ratesByRole.find((r) => r.roleId === role._id)

            if (roleRate) {
                return { ...role, ...roleRate, cost: roleRate.rate, _id: role._id }
            }
        }
    }

    return role
}
/*
export function getAmountForAction({ action, mission }) {
    let data = {
        estRevenue: 0,
        actualRevenue: 0,
        estCost: 0,
        actualCost: 0,
    }

    if (!action) {
        return data
    }

    const pc = getActionPercentComplete(action)
    const hours = getActionHours({ action, mission })

    if (action.billUnit === "One time") {
        data.estRevenue = ensureNumber(action.billRate)
        data.actualRevenue = ensureNumber(Math.round(ensureNumber(action.billRate) * (pc / 100)))
        data.estCost = ensureNumber(action.rate)
        data.actualCost = ensureNumber(Math.round(ensureNumber(action.rate) * (pc / 100)))
    } else if (action.billUnit === "Hourly") {
        data.estRevenue = ensureNumber(action.billRate * hours)
        data.actualRevenue = ensureNumber(action.billRate * hours)
        data.estCost = ensureNumber(action.rate * hours)
        data.actualCost = ensureNumber(Math.round(action.rate * hours * (pc / 100)))
    }

    return data
}
function getChildren(array, id) {
    return array.reduce((r, obj) => {
        if (obj.parentId === id) {
            r.push(obj, ...getChildren(array, obj._id))
        }
        return r
    }, [])
}*/
