import api from "../api.service"
import queryString from "query-string"

/**
 * EXAMPLE:
 *
 * const leaveRequest = await apiPeopleLeave.createLeaveRequest(orgId, {
 *    type: 'paid', // Default
 *    reason: 'Vacationing at my private island...',
 *    dates: [
 *        {
 *            // Single day
 *            from: {
 *                year: 2021,
 *                dayOfYear: 10
 *            }
 *        },
 *        {
 *            // Date range
 *            from: {
 *                year: 2021,
 *                dayOfYear: 18
 *            },
 *            to: {
 *                year: 2021,
 *                dayOfYear: 21,
 *                isHalfDay: true // Is half day
 *            }
 *        }
 *    ]
 * });
 *
 * await apiPeopleLeave.submitForApproval([leaveRequest._id]);
 */

/**
 * @typedef LeaveRequestDate {Object}
 * @property year {Number}
 * @property dayOfYear {Number} Range: 1-366
 * @property [isHalfDay] {Boolean}
 */

class PeopleLeaveApi {
    /**
     * Create a leave request
     * @param orgId {String}
     *
     * @param data {Object}
     * @param data.dates {LeaveRequestDate[]}
     * @param [data.type] {'paid'|'unpaid'|'sick'|'absence'|} Defaults to "paid".
     * @param [data.reason] {String} Free text for reason of leave. E.g. paternity, illness, miscarriage, etc...
     *
     * @return {Promise<any>}
     */
    createLeaveRequest(orgId, data) {
        return api
            .post(`/people-leave/create/${orgId}`, data)
            .then((res) => res.data)
            .catch((err) => {
                throw api.getResponseError(err)
            })
    }

    /**
     * Create and submit a leave request
     * @param orgId {String}
     *
     * @param data {Object}
     * @param data.dates {LeaveRequestDate[]}
     * @param [data.type] {'paid'|'unpaid'|'sick'|'absence'|} Defaults to "paid".
     * @param [data.reason] {String} Free text for reason of leave. E.g. paternity, illness, miscarriage, etc...
     * @param [options] {object}
     * @param [options.notify] {boolean} If set to false it will not notify anyone
     *
     * @return {Promise<any>}
     */
    createAndSubmitLeaveRequest(orgId, data, options = {}) {
        const qs = queryString.stringify(options)
        return api
            .post(`/people-leave/create/submit/${orgId}?${qs}`, data)
            .then((res) => res.data)
            .catch((err) => {
                throw api.getResponseError(err)
            })
    }

    /**
     * The user's leave requests.
     * @return {Promise<any>}
     */
    getLeaveRequestUserList() {
        return api
            .get(`/people-leave/list/user`)
            .then((res) => res.data)
            .catch((err) => {
                throw api.getResponseError(err)
            })
    }

    /**
     * The user's leave requests from all projects that is project manager or admin.
     * @param [options] {object}
     * @param [options.startDate] {Date|number|string} Start date to reduce the leaves range by
     * @param [options.endDate] {Date|number|string} End date to reduce the leaves range by
     * @param [options.status] {string[]} One or more statuses to filter by
     * @return {Promise<any>}
     */
    getProjectManagerLeaveRequestList(options = {}) {
        const qs = queryString.stringify(options)
        return api
            .get(`/people-leave/list/project-manager?${qs}`)
            .then((res) => res.data)
            .catch((err) => {
                throw api.getResponseError(err)
            })
    }

    /**
     * All leave requests of an Org.
     * @param orgId {String}
     * @param [options] {object}
     * @param [options.personId] {string|string[]} Filter by one or more people IDs (createdBy)
     * @return {Promise<any>}
     */
    getLeaveRequestOrgList(orgId, options = {}) {
        const qs = queryString.stringify(options)

        return api
            .get(`/people-leave/list/org/${orgId}?${qs}`)
            .then((res) => res.data)
            .catch((err) => {
                throw api.getResponseError(err)
            })
    }

    /**
     * All leave requests of an Org that overlap with the mission's planStartDate and planEndDate.
     * @param missionId {String}
     * @param [options] {object}
     * @param [options.personId] {string|string[]} Filter by one or more people IDs (createdBy)
     * @return {Promise<any>}
     */

    getLeaveRequestMissionList(missionId, options = {}) {
        const qs = queryString.stringify(options)
        return api
            .get(`/people-leave/list/mission/${missionId}?${qs}`)
            .then((res) => res.data)
            .catch((err) => {
                throw api.getResponseError(err)
            })
    }

    /**
     * Update an existing leave request.
     * Only the creator can update it and if "type" or "dates" are changed, it will require a re-submission for approval.
     *
     * @param leaveId {String}
     * @param data {Object}
     * @param data.dates {LeaveRequestDate[]}
     * @param [data.type] {'paid'|'unpaid'|'sick'|'absence'|} Defaults to "paid".
     * @param [data.reason] {String} Free text for reason of leave. E.g. paternity, illness, miscarriage, etc...
     * @return {Promise<any>}
     */
    updateLeave(leaveId, data) {
        return api
            .put(`/people-leave/action/update/${leaveId}`, data)
            .then((res) => res.data)
            .catch((err) => {
                throw api.getResponseError(err)
            })
    }

    /**
     * Update an existing leave request.
     * Only the creator can delete it.
     *
     * @param leaveId {String}
     * @return {Promise<any>}
     */
    deleteLeave(leaveId) {
        return api
            .delete(`/people-leave/action/delete/${leaveId}`)
            .then((res) => res.data)
            .catch((err) => {
                throw api.getResponseError(err)
            })
    }

    /**
     * Submit current user's one or more leave requests.
     * @param leaveIds {String|String[]}
     * @return {Promise<any>}
     */
    submitForApproval(leaveIds) {
        return api
            .put(`/people-leave/action/submit`, { leaveIds })
            .then((res) => res.data)
            .catch((err) => {
                throw api.getResponseError(err)
            })
    }

    /**
     * Approve one or more leave requests (mission level).
     * @param leaveIds {String|String[]}
     * @param [data] {Object}
     * @param [data.comment] {String} Optional comment upon approval
     * @return {Promise<any>}
     */
    approve(leaveIds, data = {}) {
        return api
            .put(`/people-leave/action/approve`, { leaveIds, payload: data })
            .then((res) => res.data)
            .catch((err) => {
                throw api.getResponseError(err)
            })
    }

    /**
     * Final approve one or more leave requests (org level).
     * @param leaveIds {String|String[]}
     * @param [data] {Object}
     * @param [data.comment] {String} Optional comment upon final approval
     * @param [options] {object}
     * @param [options.notify] {boolean} If set to false it will not notify anyone
     * @return {Promise<any>}
     */
    approveFinal(leaveIds, data = {}, options = {}) {
        const qs = queryString.stringify(options)
        return api
            .put(`/people-leave/action/approve/final?${qs}`, { leaveIds, payload: data })
            .then((res) => res.data)
            .catch((err) => {
                throw api.getResponseError(err)
            })
    }

    /**
     * Reject one or more leave requests (mission level).
     * @param leaveIds {String|String[]}
     * @param [data] {Object}
     * @param [data.comment] {String} Optional comment upon reject
     * @param [options] {Object}
     * @param [options.notify] {boolean} If set to false it will not notify anyone
     * @return {Promise<any>}
     */
    reject(leaveIds, data = {}, options = {}) {
        const qs = queryString.stringify(options)
        return api
            .put(`/people-leave/action/reject?${qs}`, { leaveIds, payload: data })
            .then((res) => res.data)
            .catch((err) => {
                throw api.getResponseError(err)
            })
    }

    /**
     * Cancel one or more leave requests (mission level).
     * @param leaveIds {String|String[]}
     * @param [data] {Object}
     * @param [data.comment] {String} Optional comment upon cancelling
     * @param [data.dateTo] {LeaveRequestDate} What the new endDate should be when cancelled early. Defaults to now
     * @param [options] {Object}
     * @param [options.notify] {boolean} If set to false it will not notify anyone
     * @return {Promise<any>}
     */
    cancel(leaveIds, data = {}, options = {}) {
        const qs = queryString.stringify(options)
        return api
            .put(`/people-leave/action/cancel?${qs}`, { leaveIds, payload: data })
            .then((res) => res.data)
            .catch((err) => {
                throw api.getResponseError(err)
            })
    }

    /**
     * Final reject one or more leave requests (org level).
     * @param leaveIds {String|String[]}
     * @param [data] {Object}
     * @param [data.comment] {String} Optional comment upon final reject
     * @param [options] {Object}
     * @param [options.notify] {boolean} If set to false it will not notify anyone
     * @return {Promise<any>}
     */
    rejectFinal(leaveIds, data = {}, options = {}) {
        const qs = queryString.stringify(options)
        return api
            .put(`/people-leave/action/reject/final?${qs}`, { leaveIds, payload: data })
            .then((res) => res.data)
            .catch((err) => {
                throw api.getResponseError(err)
            })
    }

    addAttachment(leaveId, files, options = {}) {
        return api
            .uploadFileMulti(`/people-leave/attachment/leave/${leaveId}`, files, options)
            .then((res) => res.data)
            .catch((err) => {
                throw api.getResponseError(err)
            })
    }

    removeAttachment(leaveId, fileId) {
        return api
            .delete(`/people-leave/attachment/leave/${leaveId}/file/${fileId}`)
            .then((res) => res.data)
            .catch((err) => {
                throw api.getResponseError(err)
            })
    }

    getAttachmentSignedUrlOrg(orgId, leaveId, fileId) {
        return api
            .get(`/people-leave/attachment/leave/${leaveId}/org/${orgId}/file/${fileId}`)
            .then((res) => res.data)
            .catch((err) => {
                throw api.getResponseError(err)
            })
    }

    getAttachmentSignedUrlMission(missionId, leaveId, fileId) {
        return api
            .get(`/people-leave/attachment/leave/${leaveId}/mission/${missionId}/file/${fileId}`)
            .then((res) => res.data)
            .catch((err) => {
                throw api.getResponseError(err)
            })
    }
}

export default new PeopleLeaveApi()
