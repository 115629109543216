import React from "react"
import People from "./People"
import Button from "./Button"
import IconCheck from "./icons/IconCheck"
import cx from "classnames"
import shave from "shave"
import ProgressBar from "./ProgressBar"
import { addParam } from "./History"
import { formatMissionPeople } from "./MissionUtils"
import { displayRole } from "./Utils"

class ActionTile extends React.PureComponent {
    hexToRgb(hex) {
        var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
        return result
            ? parseInt(result[1], 16) + "," + parseInt(result[2], 16) + "," + parseInt(result[3], 16)
            : "0,0,0"
    }
    getBgGradient() {
        const missionData = this.props.missionData

        if (!missionData.theme) return null

        let bgc = missionData.theme?.backgroundColor

        //if (this.props.forOrg) bgc = "87CD1F"

        const bg = this.hexToRgb(bgc) //must be rgba

        const first = this.props.fullBg ? 1 : 0.7
        const second = this.props.fullBg ? 1 : 0.3

        const st = "linear-gradient(152deg, rgba(" + bg + "," + first + "), rgba(" + bg + ", " + second + "))"

        return st
    }
    componentDidMount() {
        try {
            shave("#act-tile-" + this.props._id + " h3", 80)
            if (this.props.openMission || this.props.showMissionTitle) shave(".atile-m-title", 60)
        } catch (e) {}
    }
    componentDidUpdate() {
        try {
            shave("#act-tile-" + this.props._id + " h3", 80)
            if (this.props.openMission || this.props.showMissionTitle) shave(".atile-m-title", 60)
        } catch (e) {}
    }
    render() {
        if (!this.props.missionData) return null

        const isDark = document.body.className.indexOf("dna-dark") !== -1

        let theme = this.props.theme || this.props.missionData.theme || {}

        if (this.props.orgData?._id === "657d48b24e86b710a4b5fcdc") {
            theme = {
                backgroundColor: "red",
                backgroundImage: "url(/themes/orgs/org-catch.jpg)",
                dark: true,
            }
        }

        const cn = cx("dna-action-tile", this.props.className, {
            "dna-dark": this.props.from === "ai" && isDark ? true : theme.dark,
            "dna-action-done": this.props.status === "done",
            "dna-always-msg": this.props.alwaysBlue,
            "no-progress": !this.props.progress,
            "full-bg": this.props.fullBg,
        })

        const actionItems = this.props.actionItems || []

        const styleObj = {}

        if (theme.backgroundColor && !theme.backgroundImage) styleObj.backgroundColor = theme.backgroundColor

        if (theme.backgroundImage) styleObj.backgroundImage = this.getBgGradient()

        const progress = (actionItems.filter((item) => item.status === "done").length / actionItems.length) * 100

        let people = []

        if (this.props.type !== "invite" && this.props.people) {
            people = formatMissionPeople(this.props.people)
        } else if (this.props.type === "invite") {
            people = [{ ...this.props.people[0], ref: this.props.people[0] }]
        }

        if (this.props.orgData && this.props.orgData.roles && this.props.orgData.roles.length) {
            people = people.map((p) => {
                return {
                    ...p,
                    role: displayRole(p.role, this.props.orgData),
                }
            })
        }

        let title =
            this.props.title === "Complete" && this.props.source === "ai"
                ? "Complete " + this.props.actionData.title
                : this.props.title

        return (
            <div
                id={"act-tile-" + this.props._id}
                className={cn}
                style={styleObj}
                onClick={(e) => this.props.onClick(this.props, e)}
            >
                {theme.backgroundImage && (
                    <div
                        className="dna-tile-bg"
                        style={{
                            backgroundImage:
                                this.props.from === "ai" && isDark
                                    ? theme.backgroundImage.replace(".jpg", "-dark-tile.jpg")
                                    : theme.backgroundImage.replace(".jpg", "-tile.jpg"),
                            backgroundSize: theme.backgroundSize,
                        }}
                    ></div>
                )}
                <div className="dna-tile-content">
                    {this.props.openMission && (
                        <label className="atile-m-title" onClick={() => addParam(this.props.missionData.id)}>
                            {this.props.missionData.title}
                        </label>
                    )}
                    {this.props.showMissionTitle && (
                        <label className="atile-m-title">{this.props.missionData.title}</label>
                    )}
                    <h3>{title}</h3>

                    {this.props.msg && <div className="dna-action-tile-msg">{this.props.msg}</div>}

                    {this.props.type === "invite" && false && (
                        <p className="dna-center-text invite-msg">
                            Congratulations <br />
                            You've been invited to join the team.
                        </p>
                    )}

                    {this.props.status === "done" && this.props.type !== "invite" && (
                        <IconCheck width={32} height={30} />
                    )}

                    {actionItems.length === 1 &&
                        this.props.status !== "done" &&
                        this.props.type !== "invite" &&
                        !this.props.from === "ai" && (
                            <Button
                                onClick={(e) => {
                                    e.stopPropagation()
                                    e.preventDefault()
                                    addParam("complete-action", this.props._id)
                                }}
                            >
                                Done
                            </Button>
                        )}
                    {!(people.length === 1 && !people[0].ref?.avatar) && !this.props.noPeople && (
                        <People data={people} />
                    )}

                    {this.props.progress && (
                        <ProgressBar
                            width={progress || 2}
                            label={
                                actionItems.filter((item) => item.status === "done").length + " / " + actionItems.length
                            }
                        />
                    )}
                </div>
            </div>
        )
    }
}

ActionTile.displayName = "ActionTile"
export default ActionTile
