import React from "react"
import People from "./People"
import Badge from "@material-ui/core/Badge"
import Sonar from "./Sonar"
import Hive from "./Hive"
import Tip from "./Tip"

import { isOverdue } from "../utils/actionItem"
import { findAiEndDate } from "../utils/dates"
import { getRefId, getObjectId } from "../utils/utils"
import cx from "classnames"
import ProgressBar from "./ProgressBar"
import { addParam, addParams } from "./History"
import { isSameDay } from "date-fns/isSameDay"
import formatDistance from "date-fns/formatDistance"
import isTomorrow from "date-fns/isTomorrow"
import IconOhCheck from "./icons/IconOhCheck"
import shave from "shave"
import {
    PiClockCountdownDuotone,
    PiCheckCircleDuotone,
    PiBellSimpleRingingDuotone,
    PiCarrotDuotone,
    PiAlarmDuotone,
    PiRadioactiveDuotone,
    PiChatCircleDotsDuotone,
    PiTriangleDuotone,
} from "react-icons/pi"

import format from "date-fns/format"

import { displayRole } from "./Utils"

import apiNotification from "../services/notification/notification.api"

import "./ActionItemTile.scss"

class ActionItemTile extends React.PureComponent {
    constructor(props) {
        super(props)
        this.state = {
            comments: [],
            fadeAway: false,
            timeSpent: props.actualDuration || 0,
        }

        this.timeInterval = 0
    }
    onClickHandler(e) {
        if (this.props.onClick) {
            this.props.onClick(this.props)
            e.stopPropagation()
            return
        }

        let params = [
            {
                param: "action",
                value: this.props.actionData._id,
            },
        ]

        if (this.props.title === "Complete" && this.props.source === "ai") {
            //do nothing
        } else {
            params.push({
                param: "action-item",
                value: this.props._id,
            })
        }

        addParams(params)
    }
    componentDidMount() {
        const el = "#ai-tile-title-" + this.props._id

        shave(el, 70)
    }

    componentDidUpdate(lastProps) {
        if (lastProps.title !== this.props.title) {
            const el = "#ai-tile-title-" + this.props._id
            shave(el, 70)
        }
    }
    componentWillUnmount() {
        if (this.timeInterval) {
            this.endTimer()
        }
    }
    pauseTimer() {
        this.setState({
            timer: "paused",
        })

        window.clearInterval(this.timeInterval)

        this.props.app.actionItemUpdateX(this.props, {
            actualDuration: (this.props.duration || 0) + new Date().getTime() - this.state.timeStarted,
        })
    }
    endTimer() {
        this.props.app.actionItemUpdateX(this.props, {
            actualDuration: new Date().getTime() - this.state.timeStarted,
        })
        window.clearInterval(this.timeInterval)
        this.setState({
            timer: null,
        })
    }

    render() {
        const missionData = this.props.missionData

        const me = this

        if (!missionData) return null

        const orgData = this.props.missionData.org
            ? this.props.app.state.orgs.find((o) => getObjectId(o) === getObjectId(this.props.missionData.org))
            : null

        const theme = missionData.theme || {}

        const cext = cx("action-item-external-wrapper", {
            "action-item-fade-away": this.state.fadeAway,
        })

        const cn = cx("dna-action-item-card", this.props.className, {
            "dna-action-done": this.props.status === "done",
            "action-item-overdue": isOverdue(this.props),
        })
        const c = cx("tile-mission-img", "clip-" + Math.floor(Math.random() * 7))

        let people = []

        if (this.props.people.length && typeof this.props.people[0] === "string") {
            this.props.people.forEach((id) => {
                const person = missionData.people.find((p) => getRefId(p) === id)
                if (person) people.push(person)
            })
        } else {
            people = me.props.people
        }

        let title =
            this.props.title === "Complete" && this.props.source === "ai"
                ? "Complete " + this.props.actionData.title
                : this.props.title || "No title specified..."

        const myMsgs = this.props.app.state.notificationList.filter(
            (n) => n.data && n.data.roomName === "ActionItem" && n.data.room === this.props._id
        )

        const myUnread = myMsgs.filter((n) => !n.isRead)

        let kanbanPerc = 0

        if (missionData.projectType === "kanban") {
            const myColIndex = missionData.cols.findIndex((c) => c.id === this.props.col)

            if (myColIndex !== -1) {
                kanbanPerc = ((myColIndex + 1) / missionData.cols.length) * 100
            }
        }

        const progressWidth =
            this.props.checklist && this.props.checklist.length
                ? (this.props.checklist.filter((c) => c.done).length / this.props.checklist.length) * 100
                : kanbanPerc

        let tileHeader

        if (
            (missionData.projectType === "simple" || missionData.projectType === "kanban") &&
            missionData.sectionTags &&
            this.props.tags &&
            this.props.tags.length
        ) {
            let foundTag
            missionData.sectionTags.forEach((item, i) => {
                const ft = me.props.tags.find((t) => t === item.title)
                if (ft) {
                    foundTag = ft
                }
            })

            tileHeader = foundTag
        } else if (
            (missionData.projectType === "power" || missionData.projectType === "mx-gantt") &&
            this.props.actionData
        ) {
            tileHeader = this.props.actionData.title
        }

        return (
            <div
                className={cext}
                ref={(el) => (this.element = el)}
                onMouseEnter={() => {
                    this.setState({
                        imOverIt: true,
                    })
                }}
                onMouseLeave={() => {
                    this.setState({
                        imOverIt: false,
                    })
                }}
            >
                <div
                    className={cn}
                    onClick={(e) => {
                        if (myUnread.length) {
                            let idArray = []
                            myUnread.forEach((mu, i) => {
                                idArray.push(mu._id)
                            })

                            apiNotification
                                .markNotificationAsRead(idArray)
                                .then((res) => {})
                                .catch((err) => {})
                        }

                        this.onClickHandler(e)
                    }}
                >
                    <div className="dna-tile-content" ref={(el) => (this.scene = el)}>
                        {tileHeader && <label className="dna-action-title-label">{tileHeader}</label>}

                        <h3 id={"ai-tile-title-" + this.props._id} className="dna-action-item-title">
                            {title}
                        </h3>

                        {this.state.imOverIt &&
                            this.props.missionData &&
                            this.props.missionData.projectType !== "kanban" && (
                                <div
                                    className="dna-center action-item-finish-this fake-click"
                                    onClick={(e) => {
                                        e.stopPropagation()

                                        this.props.app.doneBurst.tune({ x: e.pageX, y: e.pageY }).generate().replay()

                                        setTimeout(() => {
                                            this.setState({
                                                fadeAway: true,
                                            })
                                        }, 480)

                                        this.props.app.actionItemUpdate(
                                            this.props._id,
                                            {
                                                status: "done",
                                            },
                                            this.props
                                        )
                                    }}
                                >
                                    <Tip title="Done?">
                                        <IconOhCheck />
                                    </Tip>
                                </div>
                            )}

                        {myUnread.length > 0 && (
                            <div className="tile-msgs">
                                <Badge badgeContent={myUnread.length}>
                                    <PiChatCircleDotsDuotone />
                                </Badge>
                            </div>
                        )}
                        {this.props.metaType === 0 && (
                            <Tip title="Critical task 🔥" red className={"ai-nuke active"}>
                                <Sonar color="silver">
                                    <PiRadioactiveDuotone />
                                </Sonar>
                            </Tip>
                        )}

                        <div
                            className={c}
                            style={{ backgroundImage: theme.backgroundImage?.replace(".jpg", "-tile.jpg") }}
                        >
                            <Tip title={"Jump to mission: " + this.props.missionData.title} position="top">
                                <label
                                    onClick={(e) => {
                                        e.stopPropagation()

                                        addParam("mission", this.props.missionData._id)
                                    }}
                                    className="tile-mission-title"
                                >
                                    {this.props.missionData.title}
                                </label>
                            </Tip>
                        </div>

                        <Hive
                            app={this.props.app}
                            width={30}
                            ai={this.props}
                            people={this.props?.people.map((p) =>
                                this.props.missionData?.people?.find((pp) => getRefId(pp) === getRefId(p))
                            )}
                            orgData={orgData}
                            mission={this.props.missionData}
                            offset={1}
                            sty
                        />

                        <Footer ai={this.props} mission={this.props.missionData} />
                        {this.props.showDuration && (
                            <ProgressBar
                                color={
                                    this.props.status === "done"
                                        ? "aqua"
                                        : isOverdue(this.props)
                                        ? "#FD2A7D"
                                        : undefined
                                }
                                width={progressWidth}
                            />
                        )}
                    </div>
                </div>
            </div>
        )
    }
}

const Footer = ({ ai, mission }) => {
    const myDueDate = findAiEndDate(ai)

    if (myDueDate && isNaN(Date.parse(myDueDate))) {
        const x = {
            myDueDate: myDueDate,
            _id: ai._id,
            title: ai.title,
            startDate: ai.startDate,
            week: ai.week,
            endDate: ai.endDate,
            estimatedDuration: ai.estimatedDuration,
            dueDate: ai.dueDate,
            mission: mission._id,
            missionTitle: mission.title,
            type: mission.isModel ? "model" : mission.isProposal ? "proposal" : "project",
        }

        throw Error(myDueDate?.toString() + " aiId: " + JSON.stringify(x))
    }

    const overdue = isOverdue(ai)

    const today = isSameDay(new Date(), myDueDate)
    const tomorrow = isTomorrow(myDueDate)

    return (
        <div className="ai-tile-footer">
            {ai.status === "done" ? (
                <div>
                    <PiCheckCircleDuotone color="green" /> Complete
                </div>
            ) : overdue && !ai.endDate && myDueDate ? (
                <div>
                    <PiTriangleDuotone color="orange" /> Expected {formatDistance(new Date(), myDueDate)} ago
                </div>
            ) : overdue ? (
                <div>
                    <PiClockCountdownDuotone color="red" /> Overdue {formatDistance(new Date(), myDueDate)}
                </div>
            ) : today ? (
                <div>
                    <PiBellSimpleRingingDuotone color="orange" /> {ai.endDate ? "Due today" : "Estimated for today"}
                </div>
            ) : tomorrow ? (
                <div>
                    <PiAlarmDuotone color="gold" /> Due tomorrow
                </div>
            ) : myDueDate ? (
                <div>
                    <PiCarrotDuotone color="orange" /> Due on {format(myDueDate, "eee MMM d")}
                </div>
            ) : (
                <></>
            )}
        </div>
    )
}

ActionItemTile.displayName = "ActionItemTile"
export default ActionItemTile
